"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainFrameNavigator = exports.MainFrameNavigator = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/searchable-tree-menu/lib-es2015");
class MainFrameNavigator extends HTMLElement {
    constructor() {
        super();
        this.router = (0, lib_es2015_1.useRouter)();
        const isMobile = window.matchMedia(`(max-width: 600px)`).matches;
        const menuCfg = {
            name: ``,
            children: [
                {
                    name: `Location History`,
                    onMenuClick: () => {
                        this.router.push(`/web/location/history`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Recent Location`,
                    onMenuClick: () => {
                        this.router.push(`/web/location/recent`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Api Token`,
                    onMenuClick: () => {
                        this.router.push(`/web/member/api-token`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
            ],
            isFold: false,
        };
        const treeMenu = new lib_es2015_2.MenuNode(menuCfg, 0, undefined);
        this.appendChild(treeMenu);
        if (isMobile)
            this.style.setProperty('display', 'none');
    }
}
exports.MainFrameNavigator = MainFrameNavigator;
const defineMainFrameNavigator = (name) => {
    (0, lib_es2015_2.define)(`searchable-tree-menu`);
    (0, lib_es2015_1.defineIfUndefined)(name, MainFrameNavigator);
};
exports.defineMainFrameNavigator = defineMainFrameNavigator;
