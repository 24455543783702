"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineLocationHistory = exports.LocationHistory = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/webapp-common/lib-es2015");
const kakao_map_controller_1 = require("./kakao-map-controller");
const apis_1 = require("./apis");
class LocationHistory extends HTMLElement {
    constructor() {
        super();
        this.locationApis = new apis_1.default();
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <div class="control-panel">
      <span>User:</span>
      <select class="email">
        <option>user name</option>
      </select>
      <span>Start:</span>
      <input type="datetime-local" class="start-time-local" step="1" />
      <span>Stop:</span>
      <input type="datetime-local" class="stop-time-local" step="1" />
      <span>Sampling:</span>
      <select class="sampling-divisor">
        <option value="1">sampling rate</option>
        <option value="1">100%</option>
        <option value="2">50%</option>
        <option value="3">33%</option>
        <option value="4">25%</option>
        <option value="5">20%</option>
        <option value="10">10%</option>
        <option value="20">5%</option>
      </select>
      <button class="search">Search</button>
      <button class="remove">Remove</button>
    </div>
    <div class="location-history-map"></div>
    <script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=61bfbe8a1b17cd9e8a771f6257ee7c69"></script>`;
        this.emailSelector = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'select.email');
        this.startTimeLocalInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.start-time-local');
        this.stopTimeLocalInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.stop-time-local');
        this.samplingRateSelector = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'select.sampling-divisor');
        this.searchBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.search');
        this.removeBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.remove');
        const tempTime = new Date();
        this.stopTimeLocalInput.value = (0, lib_es2015_3.toRFC3339WithoutZone)(tempTime);
        tempTime.setDate(tempTime.getDate() - 2);
        this.startTimeLocalInput.value = (0, lib_es2015_3.toRFC3339WithoutZone)(tempTime);
        this.searchBtn.addEventListener('click', e => {
            const startDatetime = new Date(this.startTimeLocalInput.value);
            const stopDatetime = new Date(this.stopTimeLocalInput.value);
            if (startDatetime > stopDatetime) {
                alert('The stop datetime is earlier than start datetime, please pick datetimes correctly.');
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const entity = yield this.locationApis.getLocationHistory(this.emailSelector.value, startDatetime, stopDatetime);
                if (entity === null || Object.keys(entity).length === 0) {
                    alert('no data.');
                    return;
                }
                this.kakaoMapController.deletePolyLines();
                this.kakaoMapController.drawPolyLinesByEntity(entity, Number(this.samplingRateSelector.value));
            }))();
        });
        this.removeBtn.addEventListener('click', e => {
            this.kakaoMapController.deletePolyLines();
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const emails = yield this.locationApis.getEmails();
            if (emails === null) {
                alert('fail to get email list');
                return;
            }
            emails.forEach(email => {
                this.emailSelector.appendChild((0, lib_es2015_2.buildNodeByOuterHTML)(`<option value="${email}">${email}</option>`));
            });
            // KakaoMapController should be constructed in connectedCallback rather than in constructor, because, it needs map container which has been completely initiated.
            this.kakaoMapController = new kakao_map_controller_1.KakaoMapController((0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.location-history-map'));
        });
    }
}
exports.LocationHistory = LocationHistory;
const defineLocationHistory = (name) => (0, lib_es2015_1.defineIfUndefined)(name, LocationHistory);
exports.defineLocationHistory = defineLocationHistory;
